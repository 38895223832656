<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Lista de Clientes</h4>

          <div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'cliente' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-input-group>
              <b-form-input
                v-model="filtro.search"
                placeholder="pesquisar cliente..."
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="info" @click.prevent="listCustomer()">
                  <i class="bx bx-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="customers.length <= 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5 text-center">
            <h5 class="mb-3">Nenhum item encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div v-for="user in customers" :key="user.id" class="col-xl-3 col-sm-6">
        <div class="card text-center">
          <div class="card-body">
            <div class="mb-4">
              <img
                class="rounded-circle avatar-sm"
                v-if="user.avatar && user.avatar != '' || user.avatar != null"
                 :src="user.avatar"
                alt
              />
               <img
               v-else
                class="rounded-circle avatar-sm"
                 src="@/assets/images/notimg.jpg"
              />
            </div>
            <h5 class="font-size-15 text-dark">
              {{ user.name }}
            </h5>
            <h6 class="font-size-13 text-muted">
              {{ user.email }}
            </h6>
            <p class="text-muted">
              {{ user.created_at | moment('DD/MM/YYYY') }}
            </p>
            <span
              class="badge badge-pill badge-soft-success font-size-12"
              :class="{
                'badge-soft-success': `${user.status}` == 0,
                'badge-soft-danger': `${user.status}` == 1,
              }"
            >
              {{ user.status == 0 ? 'Ativo' : 'Bloqueado' }}
            </span>
          </div>
          <div
            class="card-footer bg-transparent border-top text-center font-size-20"
          >
            <button
              class="btn btn-success"
              v-b-tooltip.hover.top
              title="Visualizar"
              @click.prevent="
                $router.push({
                  name: 'detalhe-cliente',
                  params: { id: user.id },
                })
              "
            >
              <i class="fas fa-eye text-white"></i>
            </button>
            <button
              class="btn btn-info ml-2"
              v-b-tooltip.hover.top
              title="Editar"
               @click.prevent="
                      $router.push({
                        name: 'editar-cliente',
                        params: { id: user.id },
                      })
                    "
            >
              <i class="fas fa-pencil-alt text-white"></i>
            </button>
            <button
              class="btn btn-danger ml-2"
              v-b-tooltip.hover.top
              title="Remover"
              @click.prevent="removerItem(user)"
            >
              <i class="fas fa-trash-alt text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-5" v-if="loadingbtn">
      <div class="col-12 text-center">
        <b-button variant="info" @click.prevent="carregarMais()">
          <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
          Carregar Mais
        </b-button>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner" class="text-info"></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      customers: [],
      loading: true,
      error: false,
      loadingbtn: true,
      filtro: {
        limit: 20,
        page: 1,
      },
    }
  },
  watch: {
    'filtro.search': function () {
      if (this.filtro.search == '') {
        this.filtro = {
          page: 1,
          limit: 20,
        }
      }
      this.listCustomer()
    },
  },
  methods: {
    ...mapActions('customer', ['ActionCustomerAll', 'ActionCustomerUpdate']),
    async infiniteHandler($state) {
      this.loadingbtn = true
      try {
        await this.ActionCustomerAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.customers.push(...res.data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.customers = []
        this.loading = false
        this.error = true
        $state.complete()
      }
    },
    async carregarMais() {
      try {
        await this.ActionCustomerAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.customers.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.customers = []
        this.loading = false
        this.error = true
      }
    },
    listCustomer() {
      this.filtro.page = 1
      this.loadingbtn = true
      this.loadingCustomer()
    },
    async loadingCustomer() {
      try {
        await this.ActionCustomerAll(this.filtro).then((res) => {
          if (res.data.data.list != null && res.data.data.list.length) {
            this.filtro.page = 2
            this.customers = res.data.data.list
          } else {
            this.customers = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.customers = []
      }
    },
    removerItem(data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(data)
        }
      })
    },
    async removerItemUp(data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionCustomerUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.customers.splice(this.customers.indexOf(t), 1)
      } catch (_) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
